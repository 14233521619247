<template>
  <div class="menu-wrapper">
    <div class="menu-wrapper">
      <transition name="fade" mode="out-in">
        <div
          class="modal_deep_dive modal_deep_dive_fullVideo"
          v-if="activeCard.type ==='video'"
          key="1"
        >
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
              <!--Video link src="~@/assets/videos/Haefele_YES_Teaser_FINAL.mp4"-->
              <video class="video_fullscreen" loop controls playsinline>
                <source src="@/assets/videos/Haefele_YES_Teaser_FINAL.mp4" type="video/mp4" />Your browser does not support the video tag.
              </video>
          <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div
          class="modal_deep_dive modal_deep_dive--bgImage"
          v-if="activeCard.type ==='webflow_02'"
          key="2"
        >
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_02">
            <div class="left_side">
              <div>
                <h5 class="card__headline--top">{{activeCard.headline_top}}</h5>
                <h1 class="card__headline">{{activeCard.headline}}</h1>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="middle__wrapper">
              <div class="webflow_02--img">
                <img src="~@/assets/images/haefele_berater_app.png" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.imageSlug"></h2>
              </div>
            </div>
            <div class="right_side">
              <div>
                <!-- <h5 class="card__headline--top">{{activeCard.headline_top}}</h5> -->
                <h1 class="card__headline card__headline--right">{{activeCard.text}}</h1>
              </div>
            </div>
            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03'" key="3">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/01_WebPlaner.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03-1'" key="4">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/02_Oberflaechen.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>
        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03-2'" key="5">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/03_Rundgang.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>
        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03-3'" key="6">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/07_Planung_Grundrisse.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>
        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03-4'" key="7">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/08_Moebelbibliothek.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>
        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_03-5'" key="8">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="laptop_wrapper">
                <img class="mockup" src="~@/assets/images/macbook-mockup_16zu9.png" alt="mockup" />

                <video
                  class="preview-video"
                  width="85%"
                  height="auto"
                  muted
                  autoplay
                  loop
                  playsinline
                >
                  <source src="@/assets/videos/10_Rechnung.mp4" type="video/mp4" />
Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div
            v-if="!showOverview && this.active <= cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive_video" v-if="activeCard.type ==='webflow_04'" key="9">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_04">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="webflow_04--video">
              <video muted autoplay loop playsinline id="video">
                <source src="~@/assets/videos/04_vr.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div
            v-if="this.active < this.cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive_video" v-if="activeCard.type ==='webflow_05'" key="10">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="webflow_05--video">
              <video muted autoplay loop playsinline id="video">
                <source src="~@/assets/videos/05_freude.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div
            v-if="this.active < this.cards.length"
            @click="paginateMenu(active+1)"
            class="scroll-down vert-move"
          >
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div
          class="modal_deep_dive modal_deep_dive_06"
          v-if="activeCard.type ==='webflow_06'"
          key="11"
        >
          <div @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_06">
            <div class="webflow_06--bgImage"></div>
            <div class="left_side">
              <div>
                <h5>{{activeCard.headline_top}}</h5>
                <h1>{{activeCard.headline}}</h1>
                <div class="point" v-for="(point,i) in activeCard.points" :key="i">
                  <span>{{i+1}}</span>
                  <p>{{point}}</p>
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="right_side">
              <img src="@/assets/images/Yes-download.svg" alt="cloudSynch" />
            </div>
          </div>
          <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_07'" key="12">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_07">
            <div class="left_side">
              <div>
                <h5 class="card__headline--top">{{activeCard.headline_top}}</h5>
                <h1 class="card__headline">{{activeCard.headline}}</h1>
              </div>
              <div>
                <div class="point">
                  <div class="point__text">
                    <span>1</span>
                    <p>{{activeCard.points[0]}}</p>
                  </div>
                  <img src="@/assets/images/08_WebFlow_11-1.svg" alt />
                </div>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="middle__wrapper">
              <div class="point">
                <div class="point__text">
                  <span>2</span>
                  <p>{{activeCard.points[1]}}</p>
                </div>
                <img src="@/assets/images/08_WebFlow_11-2.svg" alt />
              </div>
            </div>
            <div class="right_side">
              <div class="point">
                <div class="point__text">
                  <span>Coming soon</span>
                  <p>{{activeCard.points[2]}}</p>
                </div>
                <img src="@/assets/images/08_WebFlow_11-3.svg" alt />
              </div>
            </div>
            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_08'" key="13">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_08">
            <div class="left_side">
              <div>
                <h5 class="card__headline--top">{{activeCard.headline_top}}</h5>
                <h1 class="card__headline">{{activeCard.headline}}</h1>
              </div>
              <div>
                <div class="point">
                  <img src="@/assets/images/08_WebFlow_13-1.svg" alt />
                  <div class="point__text">
                    <span>1</span>
                    <p>{{activeCard.points[0]}}</p>
                  </div>
                </div>
              </div>
              <div class="buttonwrap">
                <!-- <HaefeleButton
                  @click="openNotes()"
                  :title="$t('views.buttons.add_to_watchlist')"
                  :icon="activeCard.inFavorites? 'Icon-Done.svg': 'Icon-Notes.svg'"
                  class="webflow_08--button"
                /> -->
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                  class="webflow_08--button"
                />
              </div>
            </div>
            <div class="middle__wrapper">
              <img src="@/assets/images/08_WebFlow_13-2.svg" alt />
              <div class="point">
                <div class="point__text">
                  <span>2</span>
                  <p>{{activeCard.points[1]}}</p>
                </div>
              </div>
            </div>
            <div class="right_side">
              <div class="point">
                <img src="@/assets/images/08_WebFlow_13-3.svg" alt />
                <div class="point__text">
                  <span>3</span>
                  <p>{{activeCard.points[2]}}</p>
                </div>
              </div>
            </div>

            <div class="right_side_2">
              <div class="point">
                <img src="@/assets/images/08_WebFlow_13-4.svg" alt />
                <div class="point__text">
                  <span>4</span>
                  <p>{{activeCard.points[3]}}</p>
                </div>
              </div>
            </div>
            <!-- <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>-->
          </div>
        </div>
      </transition>
    </div>
    <div class="menu__sidenav">
      <div v-for="i in cards.length" :key="i" class="menu__point">
        <span @click="paginateMenu(i)" :class="['square', {active: active === i}]"></span>
        <span v-if="i != cards.length" class="line"></span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import HaefeleButton from '@/components/shared/Button';

export default {
  components: {
    HaefeleButton
  },
  props: {
    showPopup: Boolean,
    closeProduct: Function,
    openPopup: Function,
    productId: String,
    inWindow: Boolean,
    toggleShowChat: Function,
    setSelectedUser: Function,
    selectedNote: String,
    setSelectedExhibit: Function,
    goToNotes: Function,
    toggleShowProduct: Function
  },
  data: () => ({
    exhibit: null,
    active: 1,
    showOverview: false,
    cards: [
      {
        type: 'webflow_02',
        headline:
          'Fotorealistische Planung in Echtzeit. Und Ihre Kunden sagen schneller „YES!“',
        imageSlug: 'Die Häfele <br /> Berater App',
        text:
          'Intuitiv planen. Ohne CAD-Vorkenntnisse. Entdecken Sie das Potenzial für Ihren Verkauf.'
      },
      {
        type: 'video',
        id: 1,
        heading: 'Themenwelt Küche',
        subHeading:
          'Schranksysteme Versatile und Dresscode. Modular, flexibel, effizient.',
        description:
          'Sortiment Versatile & Dresscode. Aktionsfläche Montage Dresscode/Versatile. Vorführung Planung mit Dresscode Konfigurator. Inszenierungen Versatile & Dresscode.',
        image: '~assets/images/Haefele_menu1.png'
      },
      {
        type: 'webflow_03',
        headline_top: 'Intensiver Erstkontakt ohne Zeitaufwand',
        headline: 'Webplaner für Ihre Homepage',
        points: [
          'Kunden-Akquise online',
          'erste Grobplanung des Kunden auf Ihrer Homepage – ohne Aufwand für Sie',
          'intensive Kundenbindung schon beim Erstkontakt'
        ],
        videolink: '01_WebPlaner.mp4'
      },
      {
        type: 'webflow_03-1',
        headline_top: 'Ein verkaufsförderndes Kundenerlebnis',
        headline: 'Einzigartige Darstellungs- Qualität',
        points: [
          'Oberflächen, Materialien und Texturen werden realitätsnah dargestellt',
          'Türen, Klappen, Schubladen und Beschläge können in Bewegung präsentiert werden',
          'steuerbare Lichtstimmung und Reflektionen schaffen eine emotionale Atmosphäre'
        ],
        videolink: '02_Oberflaechen.mp4'
      },
      {
        type: 'webflow_03-2',
        headline_top: 'Statisch oder in Bewegung',
        headline: 'IHR VIRTUELLER SHOWROOM',
        points: [
          'Präsentation aller geplanten und realisierten Möbel- und Raumlösungen in Fotoqualität auf Ihrer Homepage oder für Kundenpräsentationen als virtueller Rundgang in Echtzeit',
          'realitätsnahe Abbildung (Export von Standbildern) für Ihre Homepage oder Social Media'
        ],
        videolink: '03_Rundgang.mp4'
      },
      {
        type: 'webflow_04',
        headline_top: 'Begeisterung im Maßstab 1:1',
        headline: 'PRÄSENTATION IN VIRTUAL REALITY',
        points: [
          'intensiver Wow-Effekt für Ihre Kunden',
          '„Live-Erlebnis“ der Planung im Maßstab 1:1',
          'Testmöglichkeit für Ergonomie, Handling und Bedienfunktionen der Möbellösungen'
        ]
      },
      {
        type: 'webflow_05',
        headline_top: 'Der Visualisierer für zu Hause',
        headline: 'ENTSCHEIDUNGSHILFE IN 3D',
        points: [
          'frei „begehbare“ 3D-Ansicht der Planung für Ihre Kunden – einfach per Link, kein Download einer App oder Software notwendig',
          'der Impulsverstärker für Ihr Angebot'
        ]
      },
      {
        type: 'webflow_06',
        headline_top: 'Integrierte Aktualisierung und Absicherung',
        headline: 'AUTOMATISCHE UPDATES, SICHERUNG UND SUPPORT',
        points: [
          'automatische Aktualisierung der Software bei jedem Programmstart',
          'automatische Sicherung bei jedem Planungsschritt',
          'Live-Support/Fernwartung innerhalb der Software'
        ]
      },
      {
        type: 'webflow_03-3',
        headline_top: 'Maßgenaue Abmessungen',
        headline: 'Flexible Raumplanung',
        points: [
          'Planung flexibler Grundrisse – auch für mehrere Räume',
          'maßgenaue Integration von Fenstern, Türen oder Dachschrägen',
          'maßgenaue Planung von Installationen wie Lichtschaltern, Steckdosen, Wasseranschlüssen'
        ],
        videolink: '07_Planung_Grundrisse.mp4'
      },
      {
        type: 'webflow_03-4',
        headline_top: 'Schnell und individuell',
        headline: 'EFFIZIENTE MÖBELPLANUng',
        points: [
          'umfangreiche Möbelbibliothek mit intelligenten vorkonfigurierten Korpussen',
          'individuelle Anpassungs­möglichkeit für Maße, Materialstärken, Ausstattungen, Aufteilungen etc.',
          'vorinstallierte Beschläge – mit der Möglichkeit zum Austausch'
        ],
        videolink: '08_Moebelbibliothek.mp4'
      },
      {
        type: 'webflow_07',
        headline_top: 'Einfacher Datenimport und -export',
        headline: 'SchnittstElle für externe 3D-daten',
        points: [
          'problemloser Import eigener CAD-Daten',
          'einfache Integration von allen gängigen Standard-Küchenkatalogen der Branche und Katalogen für Elektrogeräte',
          'Ausblick: Export Ihrer Planungsdaten als CAD-Datei für Ihre Konstruktions-Software'
        ]
      },
      {
        type: 'webflow_03-5',
        headline_top: 'Angebot per Mausklick',
        headline: 'Einzigartige Darstellungs- Qualität',
        points: [
          'integriertes Kalkulations-Tool zur individuellen Preisermittlung. Preis und Verfügbarkeit von Häfele Beschlägen werden direkt in der Software geprüft. Bestellung der Beschläge direkt aus der Software',
          'Angebots- und Rechnungsstellung direkt aus der Software'
        ],
        videolink: '10_Rechnung'
      },
      {
        type: 'webflow_08',
        headline_top: 'Ihre Vorteile auf einen Blick',
        headline: 'EINZIGARTIG BERATEN, BEGLEITEN UND BEGEISTERN',
        points: [
          'bis zu 50 % höhere Abschlusswahrscheinlichkeit',
          'deutlich erhöhte Reichweite und Neukundengewinnung.',
          'bis zu 70 % Zeitersparnis im Verkaufsprozess.',
          'YES! – die Häfele Berater App ist die perfekte Software-Lösung für die ersten Schritte im Kontakt mit Ihrem Kunden.'
        ]
      }
    ]
  }),
  computed: {
    activeCard() {
      return this.cards[this.active - 1];
    }
  },
  created() {
    window.addEventListener(
      'wheel',
      _.debounce(this.handleScroll, 100, {
        leading: true,
        trailing: false
      })
    );
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
  },
  mounted() {
    this.getExhibit();
  },
  methods: {
    async getExhibit() {
      var url = (await this.$store.getters.isAuthorized)
        ? '/exhibit'
        : '/shared-exhibit';
      this.$nextTick(async () => {
        let resp = await this.$http.get(url, {
          params: { uuid: this.productId }
        });
        this.exhibit = resp.data;
        this.files = this.exhibit.files;
        if (this.files) {
          this.images = this.files.images;
          this.videos = this.files.videos;
        }
        this.$store.getters.isAuthorized ? this.getNotes() : '';
      });
    },
    getNotes() {
      this.$http
        .get('/notes')
        .then(resp => {
          this.checkFavorites(resp.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    openNotes() {
      this.closeProduct();
      this.setSelectedExhibit(this.exhibit);
      this.goToNotes();
    },
    handleScroll(event) {
      if (this.$store.state.popup) {
        return;
      }
      console.log(this.cards.length, this.active);
      if (this.active === this.cards.length && event.deltaY > 0) {
        this.active = 0;
      }
      if (this.active === 1 && event.deltaY < 0) {
        this.active = this.cards.length + 1;
      }
      if (event.deltaY > 0) {
        this.active += 1;
      }
      if (event.deltaY < 0) {
        this.active -= 1;
      }
    },
    paginateMenu(i) {
      this.active = i;
    },
    async goToChat() {
      this.closeProduct();
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          );
          this.$store.state.modalMessage =
            'Chat wurde angefragt. Ein Mitarbeiter meldet sich in Kürze.';
          this.$store.state.modalIcon = 'fas fa-check-circle';
          this.$store.state.modalClass = 'green-text';
          this.$store.state.modal = true;
          setTimeout(
            function() {
              this.$store.state.modal = false;
            }.bind(this),
            3000
          );
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    },
    menuToggle() {
      this.showOverview = true;
    }
  }
};
</script>

<style scoped lang="scss">
  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
  @keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }

.menu-wrapper {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.scroll-down {
  position: absolute;
  bottom: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}
.scroll-up {
  position: absolute;
  top: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}

.mockup {
  position: relative;
  z-index: 1;
  width: 100%;
}

.laptop_wrapper {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.preview-video {
  position: absolute;
  right: 7%;
  bottom: 6%;
  padding: 20px 45px 58px 15px;
  max-width: 883px;

  @include respond(laptop) {
    padding: 20px 49px 79px 31px;
    bottom: 8%;
    left: 9%;
  }

  @include respond(laptop-small) {
    padding: 20px 40px 63px 21px;
    bottom: 14%;
  }
}

.modal_deep_dive {
  position: relative;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050e6;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;
  }
}

.modal_deep_dive--bgImage {
  background-image: url("~assets/images/08_WebFlow_02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.modal_deep_dive_06 {
  background-image: url("~assets/images/08_WebFlow_08.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.modal_deep_dive_video {
  position: relative;
  overflow: hidden;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050aa;
  overflow: hidden;
  
  .left_side {
    padding: 40px;
    padding-right: 0;
  }
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;

    @include respond(laptop-small) {
      font-size: 14px;
    }
  }
}
.video_fullscreen{
    max-width: 100%;
    max-height: 100%;
  }
.point {
  font-family: "Roboto";
}

.buttonwrap {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 87%;
}

.button {
  padding: 5px;
  text-align: left;
  max-width: 328px;
}

.video_full {
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    max-width: 100%;
    width: auto;
    max-height: 75vh;
  }
  position: relative;
}

.webflow_02 {
  height: 100%;
  @include display-grid($gap: 25px);

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: "Roboto" sans-serif;
    text-transform: uppercase;
    text-align: right;
    @include respond(laptop-small) {
      font-size: 2rem;
    }
  }
  .left_side {
    @include display-column(center);
    justify-content: flex-end;

    h1 {
      padding-bottom: 32%;

      @include respond(laptop-small) {
        padding-bottom: 30%;
      }
    }
  }

  .image-slug {
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    font-weight: 300;
    padding-top: 50px;
  }

  .card__headline--right {
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 300px;
      @include respond(laptop-small) {
        height: 150px;
      }
    }
  }
}

.webflow_03 {
  display: flex;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(laptop) {
      width: 40%;
    }
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    text-transform: uppercase;
  }
  .point {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #888888;
      font-family: "Roboto", sans-serif;
      text-align: start;
      margin-top: -35px;

      @include respond(laptop) {
        margin-top: -25px;
        font-size: 40px;
      }
    }
  }
}

.webflow_04 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}
.webflow_05 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}

.webflow_06 {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  .left_side {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 67%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
      height: 80%;
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    width: 130%;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }
}

.webflow_07 {
  display: flex;
  position: relative;
  height: 100%;

  .left_side {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .point {
      margin-top: -40px;
      &__text {
        padding-bottom: 20px;
      }
      @include respond(laptop) {
        margin-top: -15px;
        &__text {
          padding-bottom: 45px;
        }
      }
    }
  }
  .middle__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right_side {
    position: relative;
    width: 33%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-left: 10px;

    .point {
      margin-top: 30px;

      img {
        margin-top: 20px;
      }
      span {
        line-height: 30px;
        font-size: 32px;
        text-align: right;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    text-transform: uppercase;
    width: 130%;
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text {
      display: flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img {
      width: 50%;
    }
  }
}
.webflow_08 {
  display: flex;
  position: relative;
  height: 100%;

  &--button {
    width: 370px;
    @include respond(laptop) {
      width: 335px;
    }
    @include respond(laptop-small) {
      width: 285px;
    }
  }

  .left_side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .point {
      // margin-top: -15px;
      &__text {
        padding-bottom: 20px;
      }
    }
  }
  .middle__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;

    @include respond(laptop-small) {
      padding-top: 110px;
    }

    img {
      height: 60px;
    }
  }
  .right_side {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-left: 10px;

    @include respond(laptop-small) {
      padding-top: 90px;
    }

    .point {
      // margin-top: 30px;

      img {
        height: 60px;
      }
    }
  }
  .right_side_2 {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-left: 10px;

    @include respond(laptop-small) {
      padding-top: 120px;
    }

    .point {
      margin-top: 35px;

      img {
        height: 60px;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    width: 150%;

    @include respond(laptop-small) {
      width: 180%;
    }
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text {
      display: flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img {
      height: 60px;
    }
  }
}

.menu {
  &__point {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__sidenav {
    margin: 0 50px 0 0;
    .line {
      height: 20px;
      width: 2px;
      background: white;

      @include respond(laptop) {
        height: 15px;
      }
    }
    .square {
      margin: 10px 0;
      height: 15px;
      width: 15px;
      border: 1px solid white;
      cursor: pointer;

      @include respond(laptop) {
        height: 15px;
        width: 15px;
      }

      &__overview {
        height: 9px;
        width: 9px;
        border: 1px solid white;
        cursor: pointer;
      }
    }
    .active {
      background: white;
    }
  }

  &__overview {
    width: 20px;
    height: 20px;
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;

    padding-bottom: 100px;
  }
}

.row {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -moz-transform: scale(1); /* Firefox */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1);
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}

.fade-leave-to {
  opacity: 0;
  transform: scale(2) translate(0px, 0px);
  -ms-transform: scale(2) translate(0px, 0px); /* IE 9 */
  -moz-transform: scale(2) translate(0px, 0px); /* Firefox */
  -webkit-transform: scale(2) translate(0px, 0px); /* Safari and Chrome */
  -o-transform: scale(2) translate(0px, 0px);
}
</style>
